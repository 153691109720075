/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'

// Components
import TitleDefault from 'components/elements/TitleDefault'

const NotFoundPage = () => (
  <div className="container py-5">
    <TitleDefault>404</TitleDefault>
    <p>Pagina niet gevonden.</p>
  </div>
)

export default NotFoundPage
